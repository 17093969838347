import { MdDoneAll } from "react-icons/md";
import './style.css';

function App() {
  return (
    <div className="container">
      
      <h1 className="titulo">SIE APP By SALT<MdDoneAll size={25} color="#FFF"/></h1>

      <main className="main">
        <h2><a href="http://saltinfo.no-ip.com:7788">Auditoria</a></h2>
        <h2><a href="https://cep.salthomol.com.br">BuscaCEP</a></h2>
        <h2><a href="https://checkin.sie.app.br">Checkin</a></h2>
        <h2><a href="http://saltinfo.no-ip.com:8080">Gest&atilde;o</a></h2>
        <h2><a href="https://suporte.salthomol.com.br">Suporte</a></h2>
      </main>

    </div>
  );
}

export default App;
